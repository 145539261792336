exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-detail-advertisement-tsx": () => import("./../../../src/pages/article-detail/advertisement.tsx" /* webpackChunkName: "component---src-pages-article-detail-advertisement-tsx" */),
  "component---src-pages-article-detail-index-tsx": () => import("./../../../src/pages/article-detail/index.tsx" /* webpackChunkName: "component---src-pages-article-detail-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-cart-side-bar-tsx": () => import("./../../../src/pages/cart/sideBar.tsx" /* webpackChunkName: "component---src-pages-cart-side-bar-tsx" */),
  "component---src-pages-homepage-components-articles-index-tsx": () => import("./../../../src/pages/homepage/components/articles/index.tsx" /* webpackChunkName: "component---src-pages-homepage-components-articles-index-tsx" */),
  "component---src-pages-homepage-components-articles-slide-show-slide-show-tsx": () => import("./../../../src/pages/homepage/components/articles/slide-show/SlideShow.tsx" /* webpackChunkName: "component---src-pages-homepage-components-articles-slide-show-slide-show-tsx" */),
  "component---src-pages-homepage-components-banner-index-tsx": () => import("./../../../src/pages/homepage/components/banner/index.tsx" /* webpackChunkName: "component---src-pages-homepage-components-banner-index-tsx" */),
  "component---src-pages-homepage-components-campaign-index-tsx": () => import("./../../../src/pages/homepage/components/campaign/index.tsx" /* webpackChunkName: "component---src-pages-homepage-components-campaign-index-tsx" */),
  "component---src-pages-homepage-components-categories-index-tsx": () => import("./../../../src/pages/homepage/components/categories/index.tsx" /* webpackChunkName: "component---src-pages-homepage-components-categories-index-tsx" */),
  "component---src-pages-homepage-components-navbar-dropdown-item-item-tsx": () => import("./../../../src/pages/homepage/components/navbar/dropdown-item/Item.tsx" /* webpackChunkName: "component---src-pages-homepage-components-navbar-dropdown-item-item-tsx" */),
  "component---src-pages-homepage-components-navbar-dropdown-item-type-ts": () => import("./../../../src/pages/homepage/components/navbar/dropdown-item/type.ts" /* webpackChunkName: "component---src-pages-homepage-components-navbar-dropdown-item-type-ts" */),
  "component---src-pages-homepage-components-navbar-index-tsx": () => import("./../../../src/pages/homepage/components/navbar/index.tsx" /* webpackChunkName: "component---src-pages-homepage-components-navbar-index-tsx" */),
  "component---src-pages-homepage-components-services-grid-grid-tsx": () => import("./../../../src/pages/homepage/components/services/grid/grid.tsx" /* webpackChunkName: "component---src-pages-homepage-components-services-grid-grid-tsx" */),
  "component---src-pages-homepage-components-services-index-tsx": () => import("./../../../src/pages/homepage/components/services/index.tsx" /* webpackChunkName: "component---src-pages-homepage-components-services-index-tsx" */),
  "component---src-pages-homepage-components-services-slide-show-items-tsx": () => import("./../../../src/pages/homepage/components/services/slideShow/items.tsx" /* webpackChunkName: "component---src-pages-homepage-components-services-slide-show-items-tsx" */),
  "component---src-pages-homepage-components-services-slide-show-slide-show-tsx": () => import("./../../../src/pages/homepage/components/services/slideShow/slideShow.tsx" /* webpackChunkName: "component---src-pages-homepage-components-services-slide-show-slide-show-tsx" */),
  "component---src-pages-homepage-components-services-type-ts": () => import("./../../../src/pages/homepage/components/services/type.ts" /* webpackChunkName: "component---src-pages-homepage-components-services-type-ts" */),
  "component---src-pages-homepage-index-tsx": () => import("./../../../src/pages/homepage/index.tsx" /* webpackChunkName: "component---src-pages-homepage-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-bottom-table-tsx": () => import("./../../../src/pages/payment/bottomTable.tsx" /* webpackChunkName: "component---src-pages-payment-bottom-table-tsx" */),
  "component---src-pages-payment-index-tsx": () => import("./../../../src/pages/payment/index.tsx" /* webpackChunkName: "component---src-pages-payment-index-tsx" */),
  "component---src-pages-payment-info-index-tsx": () => import("./../../../src/pages/payment-info/index.tsx" /* webpackChunkName: "component---src-pages-payment-info-index-tsx" */),
  "component---src-pages-payment-payment-user-info-index-tsx": () => import("./../../../src/pages/payment/payment-user-info/index.tsx" /* webpackChunkName: "component---src-pages-payment-payment-user-info-index-tsx" */),
  "component---src-pages-payment-service-tsx": () => import("./../../../src/pages/payment/service.tsx" /* webpackChunkName: "component---src-pages-payment-service-tsx" */),
  "component---src-pages-product-detail-cart-tsx": () => import("./../../../src/pages/product-detail/cart.tsx" /* webpackChunkName: "component---src-pages-product-detail-cart-tsx" */),
  "component---src-pages-product-detail-index-tsx": () => import("./../../../src/pages/product-detail/index.tsx" /* webpackChunkName: "component---src-pages-product-detail-index-tsx" */),
  "component---src-pages-product-detail-service-image-tsx": () => import("./../../../src/pages/product-detail/service/image.tsx" /* webpackChunkName: "component---src-pages-product-detail-service-image-tsx" */),
  "component---src-pages-product-detail-service-index-tsx": () => import("./../../../src/pages/product-detail/service/index.tsx" /* webpackChunkName: "component---src-pages-product-detail-service-index-tsx" */),
  "component---src-pages-product-detail-service-info-tsx": () => import("./../../../src/pages/product-detail/service/info.tsx" /* webpackChunkName: "component---src-pages-product-detail-service-info-tsx" */),
  "component---src-pages-product-detail-service-service-item-tsx": () => import("./../../../src/pages/product-detail/service/service-item.tsx" /* webpackChunkName: "component---src-pages-product-detail-service-service-item-tsx" */),
  "component---src-pages-product-detail-service-table-index-tsx": () => import("./../../../src/pages/product-detail/service/table/index.tsx" /* webpackChunkName: "component---src-pages-product-detail-service-table-index-tsx" */),
  "component---src-pages-product-detail-table-info-tsx": () => import("./../../../src/pages/product-detail/table/info.tsx" /* webpackChunkName: "component---src-pages-product-detail-table-info-tsx" */),
  "component---src-pages-product-detail-table-table-tsx": () => import("./../../../src/pages/product-detail/table/table.tsx" /* webpackChunkName: "component---src-pages-product-detail-table-table-tsx" */),
  "component---src-pages-product-detail-vin-tsx": () => import("./../../../src/pages/product-detail/vin.tsx" /* webpackChunkName: "component---src-pages-product-detail-vin-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */)
}

